<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body d-flex justify-content-between">
        <div>
          <el-button @click="$router.push({name: 'LanguagesIndex'})">
            {{ $t('system.back') }}
          </el-button>
        </div>
        <div>
          <div class="d-flex">
            <el-input
                :placeholder="$t('system.search')"
                @keyup.enter.native="searchLabel"
                v-model="valueSearch"
            />
            <el-button
                @click="searchLabel"
                class="ml-3"
                type="primary"
            >
              {{ $t('system.searchLabel') }}
            </el-button>
          </div>
          <div class="pt-2">
            <el-radio
                label="name"
                v-model="searchType"
            >{{ $t('system.name') }}
            </el-radio>
            <el-radio
                label="translation"
                v-model="searchType"
            >{{ $t('system.translation') }}
            </el-radio>
          </div>
        </div>
      </div>
    </div>
    <div class="view">
      <div class="view-body">
        <static-table
            :data="model"
            :params="params"
            :thead="thead"
            @saveRow="saveRow"
        ></static-table>
        <el-card
            class="pagination-container mt-3"
            shadow="never"
        >
          <el-pagination
              :current-page="params.page"
              :page-size="params.per_page"
              :page-sizes="[10, 30, 50, 100, 300]"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
              background
              layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
// import _ from 'lodash'
// import swal from 'sweetalert2'
import notification from '../../notification/notify';
import StaticTable from '../../components/ABtable/StaticTable.vue';

export default {
  name: 'TranslationsIndex',
  components: {
    StaticTable,
  },
  data() {
    return {
      searchType: 'name',
      valueSearch: '',
      idLanguage: null,
      model: [],
      thead: [
        {
          label: 'system.label',
          key: 'label',
          sort: true,
        },
        {
          label: 'system.translation',
          key: 'translation',
          type: 'textarea',
          autosize: {
            minRows: 1,
            maxRows: 2,
          },
          sort: false,
        },
        {
          label: '',
          key: 'actionSave',
          sort: false,
          type: 'actionSave',
        },
      ],
      params: {
        column: 'name',
        direction: 'desc',
        per_page: 10,
        page: 1,
      },
      total: 0,
      backend_error_table: {},
      backend_errors: {},
      inputRequiredEmpty: [],
    };
  },
  beforeMount() {
    this.idLanguage = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    ...mapActions('translate', ['getTranslations', 'updateItemTranslation']),
    fetchData(valueSearch = '') {
      let vm = this;
      let data = {
        p: vm.params,
        id: vm.idLanguage,
        translation: '',
        name: '',
      };
      data[this.searchType] = valueSearch;
      vm.getTranslations(data).then(function (response) {
        vm.model = response.data.data;
        vm.params.per_page = parseInt(response.data.per_page);
        vm.params.page = parseInt(response.data.current_page);
        vm.total = response.data.total;
      });
    },
    saveRow(row) {
      let vm = this;
      let data = {
        row: row,
        id: vm.idLanguage,
      };
      vm.updateItemTranslation(data).then(function (response) {
        notification.notify(
            vm.$t('notify.success'),
            vm.$t('system.saved'),
            'success');
      });
    },
    searchLabel() {
      this.fetchData(this.valueSearch);
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.fetchData(this.valueSearch);
    },
    handleSizeChange(val) {
      this.params.per_page = val;
      this.fetchData(this.valueSearch);
    },
  },
};
</script>

<style scoped>

</style>
